<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    @save="onSave('Servers')"
    @cancel="onCancel('Servers')"
    >
    <template #ip>
      <p class="ip-column__description">
        IP-адрес
      </p>
      <div class="ip-column__input-wrapper">
        <sm-input
        v-model="form.ip"
        type="text"
        @input="validateIpAddress"
      />
      <p v-if="ipError" class="ip-column__error-message">{{ ipError }}</p>
      </div>
    </template>
  </sm-editable-item>
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';
import SmInput from '@/components/common/forms/SmInput.vue';
// utils
import { isValidIpAddress } from '@/utils/validateIpAddress';
import { validateFormMandatoryFields } from '@/utils/validateFormMandatoryFields';

export default {
  name: 'ServerEdit',

  components: {
    SmEditableItem,
    SmInput
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'Servers',
      pageHeader: 'Редактирование сервера',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Сервера',
          route: { name: 'Servers' },
        },
        {
          text: 'Редактирование сервера',
        },
      ],
      form: {},
      ipError: null,
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      hosts: (state) => state.common.hosts,
    }),

    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Имя',
            },
            {
              type: 'text',
              key: 'ip',
              label: 'IP-адрес',
              attributes: {
                placeholder: '###.###.###.###',
                mask: '###.###.###.###',
              }
            },
            {
              type: 'text',
              key: 'addr',
              label: 'Адрес',
            },
            {
              type: 'select',
              key: 'hostId',
              label: 'Хост',
              list: this.hosts.data,
            },
            {
              type: 'checkbox',
              key: 'isCreateOnReg',
              label: 'Создать при регистрации',
            },
            {
              type: 'textarea',
              key: 'description',
              label: 'Описание',
            },
          ],
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;
    const item = this.getItem({ name: this.controllerName, id: this.id }).then(() => {
      this.form = this.lodash.cloneDeep(this.item.data);
    })
    const hosts = this.getCommonList({ name: 'Hosts' });
    
    Promise.all([item, hosts]).finally(() => {
      this.isLoadingPage = false;
    }
  );
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      updateItem: 'editableList/updateItem',
      getCommonList: 'common/getCommonList',
    }),

    validateIpAddress() {
      this.ipError = !isValidIpAddress(this.form.ip) ? 'Некорректный IP-адрес' : null;
    },

    onSave(route) {
      const mandatoryFields = [{ key: 'name', label: 'Имя' }];

      const validationError = validateFormMandatoryFields(this.form, mandatoryFields);
      if (validationError) {
        this.showNotification(validationError, 'error');
        return;
      }

      this.processSave(route);
    },

    showNotification(header, type) {
      this.$notify({
        header,
        type,
        timer: 5000,
      });
    },

    async processSave(route) {
      this.isLoadingPage = true;
      try {
        const result = await this.updateItem({
          name: this.controllerName,
          payload: this.form,
        });
        if (result.isSucceed) {
          this.$router.push({ name: route });
        }
      } finally {
        this.isLoadingPage = false;
      }
    },
  },
};
</script>

<style lang="scss">
.ip-column__description {
  width: 250px;
  align-self: center;
}
.ip-column__input-wrapper {
  width: 100%;
}
.ip-column__error-message{
  color: var(--red);
  font-size: 12px;
}
</style>
